import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import ServiceStationTxlCardUtil,{IServiceStationTxlCardDataObj} from './serviceStationTxlCardUtil';
export default defineComponent({
    name:'ServiceStationTxlCard',
    title:'服务站通讯录',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IServiceStationTxlCardDataObj=reactive<IServiceStationTxlCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: utils.ToolsProviderApi.buildUrl('/serviceStationTxl')
            },
            //表单
            form:{},
            provinceData:[],
            cityData:[],
            //表单验证规则
            rules: {
                brandId: [utils.UtilPub.commonValidRule('请选择品牌')],
                provinceId: [utils.UtilPub.commonValidRule('请选择省')],
                cityId: [utils.UtilPub.commonValidRule('请选择市')],
                name: [utils.UtilPub.commonValidRule('请输入服务站名称')],
                address: [utils.UtilPub.commonValidRule('请输入详细地址')],
                tel: [utils.UtilPub.commonValidRule('请输入联系电话')]
            },
            otherParams:{
                toolBrandData:[]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ServiceStationTxlCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    // return [{value:1,label:'金杯'},{value:2,label:'斯威'},{value:3,label:'新能源'}]
                    return dataObj.otherParams.toolBrandData;
                }
                if(params && 'provinceId'==params.comboId){
                    return dataObj.provinceData
                }
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('provinceId'==selectId){
                if(newValue){
                    await dataObj.utilInst.getCitiesByProvince(newValue);
                }else{
                    await dataObj.utilInst.loadAllCity();
                }
                dataObj.form.cityId='';
            }
            if('cityId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler
         
        }
    }
});