import BaseBean from "@/utils/BaseBean";

export interface IServiceStationTxlCardDataObj {
    utilInst:ServiceStationTxlCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    provinceData:Array<any>
    cityData:Array<any>
    rules:TFormRule
    otherParams:any
}
export default class ServiceStationTxlCardUtil extends BaseBean{
    public dataObj:IServiceStationTxlCardDataObj;

    constructor(proxy:any,dataObj:IServiceStationTxlCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.toolBrandData=await this.utils.ToolsProviderApi.getAllToolBrand({flag:1});
        this.dataObj.provinceData= await this.utils.Api.loadAllProvince({});
        // await this.loadAllCity();
        await this.getCitiesByProvince(this.dataObj.form.provinceId);
    }
    //加载指定省份下所有的城市
    public async getCitiesByProvince(provinceId:string):Promise<void>{
        this.dataObj.cityData=await this.utils.Api.getCitiesByProvince({provinceId:provinceId});
    }
    //加载所有的城市
    public async loadAllCity():Promise<void>{
        this.dataObj.cityData= await this.utils.Api.loadAllCity({});
    }
}